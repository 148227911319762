import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { withRouter } from "react-router";
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Home from './pages/Home';
import Neues from './pages/Neues';
import Projekte from './pages/Projekte';
import Projekt from './pages/Projekt';
import Wettbewerbe from './pages/Wettbewerbe';
import Wettbewerb from './pages/Wettbewerb';
import Buero from './pages/Buero';
import Kontakt from './pages/Kontakt';
import Impressum from './pages/Impressum';
import Header from './components/Header';
import Logo from './components/Logo';
import Toggle from './components/Toggle';
import Nav from './components/Nav';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      showNav: false,
      page: null
    };

    this.handleToggleClick = this.handleToggleClick.bind(this);
    this.handleLogoClick = this.handleLogoClick.bind(this);
  }

  componentDidMount() {
  }

  getPage(pathname) {
    return pathname.split('/')[1];
  }

  handleToggleClick() {
    this.setState(state => ({ showNav: !state.showNav}));
  }

  handleLogoClick() {
    this.setState({ showNav: false});
  }

  render() {
    

    return (
      <div className={this.props.location.pathname.split('/')[1]}>
        <Header/>
        <div className="header-bar">
          <Logo clicked={this.handleLogoClick}/>
          <Toggle clicked={this.handleToggleClick} icon={!this.state.showNav}/>
          <Nav clicked={this.handleToggleClick} show={this.state.showNav}/>
          <div id="backSlot"></div>
        </div>
        
        <Route render={({location}) => (
          <TransitionGroup>
            <CSSTransition key={location.key} timeout={300} classNames="page">
              <Switch location={location}>
                <Route exact path="/" component={Home}/>
                <Route exact path="/neues" component={Neues}/>
                <Route exact path="/projekte" component={Projekte}/>
                <Route exact path="/projekt/:slug" component={Projekt}/>
                <Route exact path="/wettbewerbe" component={Wettbewerbe}/>
                <Route exact path="/wettbewerb/:slug" component={Wettbewerb}/>
                <Route exact path="/buero" component={Buero}/>
                <Route exact path="/kontakt" component={Kontakt}/>
                <Route exact path="/impressum" component={Impressum}/>
                <Route render={() => <div>Not found</div>} />
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        )}/>
      </div>
    );
  }
}

export default withRouter(App);
