import React from 'react';
import { NavLink } from 'react-router-dom';
import css from './Nav.module.css';

const Nav = (props) => {
  if (props.show) {
    return (
      <nav className={css.Nav}>
        <ul className={css.NavList}>
          <li className={css.NavItem} onClick={props.clicked}>
            <NavLink to="/neues" className={css.NavLink} activeClassName={css.NavLinkActive}>Neues</NavLink>
          </li>
          <li className={css.NavItem} onClick={props.clicked}>
            <NavLink to="/projekte" className={css.NavLink} activeClassName={css.NavLinkActive}>Projekte</NavLink>
          </li>
          <li className={css.NavItem} onClick={props.clicked}>
            <NavLink to="/wettbewerbe" className={css.NavLink} activeClassName={css.NavLinkActive}>Wettbewerbe</NavLink>
          </li>
          <li className={css.NavItem} onClick={props.clicked}>
            <NavLink to="/buero" className={css.NavLink} activeClassName={css.NavLinkActive}>Büro</NavLink>
          </li>
          <li className={css.NavItem} onClick={props.clicked}>
            <NavLink to="/kontakt" className={css.NavLink} activeClassName={css.NavLinkActive}>Kontakt</NavLink>
          </li>
          <li className={css.NavItem} onClick={props.clicked}>
            <NavLink to="/impressum" className={css.NavLink} activeClassName={css.NavLinkActive} style={{'fontSize': '16px'}}>Impressum/Datenschutz</NavLink>
          </li>
        </ul>
      </nav>
    );
  } else {
    return null;
  }
};

export default Nav;