import React from 'react';
import MasonryGrid from 'react-masonry-component';
import config from '../config';
import css from './Masonry.module.css';
import Preload from 'react-preload';
import PreloaderIcon from 'react-preloader-icon';
import Oval from 'react-preloader-icon/loaders/Oval';

const options = {
  transitionDuration: 0,
  gutter: '.masonry-grid-sizer',
  columnWidth: '.masonry-grid-sizer',
  percentPosition: true,
  itemSelector: '.masonry-grid-item',
};

const Masonry = (props) => {
  const handleLayoutComplete = (items) => {
    items.forEach((item) => {
      if (window.getComputedStyle(item.element, null).getPropertyValue('left') !== '0px') {
        item.element.style.textAlign = 'left';
      }
    });
  };

  const preloadImages = props.data.map(item => `${config.imageBase}/${item.poster.path}`);
  const preloadIndicator = (
    <PreloaderIcon
      loader={Oval}
      size={60}
      strokeWidth={8}
      strokeColor="#006064"
      duration={800}
    />
  );
  
  const items = props.data.map((item) => {
    let imgClass = 'masonry-grid-image';
    imgClass += (item.highlight) ? ' highlight' : '';

    return (
      <div key={item._id} className="masonry-grid-item">
        <a className={imgClass} href={`${props.destination}/${item.slug}`}>
          <figure >
            <img src={`${config.imageBase}/${item.poster.path}`} alt={item.title} title={item.title}/>
            <div className="container-text">
              <figcaption className={css.MasonryTitleContainer}><span className={css.MasonryTitle}>{item.title}</span></figcaption>
            </div>
          </figure>
        </a>
      </div>
    );
  });

  return (
    <Preload
      loadingIndicator={preloadIndicator}
      images={preloadImages}
    >

      <MasonryGrid
        className="masonry"
        elementType="div"
        options={options}
        disableImagesLoaded={false}
        updateOnEachImageLoad={false}
        onLayoutComplete={laidOutItems => handleLayoutComplete(laidOutItems)}
      >
        <div className="masonry-grid-sizer"></div>
        {items}
      </MasonryGrid>

    </Preload>
  );
};

export default Masonry;