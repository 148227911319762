import React from 'react';
import { Row, Col } from 'react-grid-system';
import config from '../config';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import css from './Kontakt.module.css';
import markerIcon from '../assets/logo-signet.svg';
import styles from '../GoogleMapStyles.json';

class Kontakt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      error: null,
      data: null
    };
  }

  componentDidMount() {
    fetch(`${config.apiBase}/singletons/get/contact`)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            data: result
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  }

  render() {
    const { error, isLoaded, data } = this.state;    
    let content = null;

    if (error) {
      content = <div>Error: {error.message}</div>
    } else if (!isLoaded) {
      content = <div className="loading-screen"><span>Loading...</span></div>;
    } else {
      
      content = (
        <div className="container">
          <Row className="row mb-base">
            <Col className="col" xs={12} lg={3}>
              <div className="container-text">
                <span dangerouslySetInnerHTML={{ __html: data.company }}></span>
              </div>
            </Col>
            <Col className="col" xs={12} lg={4}>
              <div className="container-text">
                <span dangerouslySetInnerHTML={{ __html: data.people }}></span> 
              </div>
            </Col>
            <Col className="col" xs={12} lg={3}>
              <div className="container-text">
                <span dangerouslySetInnerHTML={{ __html: data.address }}></span>
              </div>
            </Col>
          </Row>
          <Row className="row">
            <Col className="col" xs={12}>
              <Map className={css.Map}
                google={this.props.google}
                zoom={18}
                initialCenter={{lat: 48.119533, lng: 11.571505}}
                styles={styles}
              >
                <Marker
                  icon={{
                    url: markerIcon,
                    anchor: new this.props.google.maps.Point(32,32),
                    scaledSize: new this.props.google.maps.Size(35,35)
                  }}
                />
              </Map>
            </Col>
          </Row>
        </div>
      );
    }

    return (
      <section className="section">
        <div className="section-container">
          <h2 className="section-headline">Kontakt</h2>
          {content}
        </div>
      </section>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDEKglUjUtIciC5l5MVtwlGFy1klI2uxjY'
})(Kontakt);
//export default Kontakt