import React from 'react';
import css from './ButtonSwitch.module.css';

const ButtonSwitch = (props) => {
  
  return (
    <button onClick={() => { 
      const el = document.getElementById(props.target);

      let elDisplay = el.style.display;
      console.log(elDisplay);    
      if (elDisplay === null || elDisplay === 'table') {
        el.style.display = 'none';
      } else {
        el.style.display = 'table';
      }
    }} className={css.ButtonSwitch}><span>{props.label}</span></button>
  );
}

export default ButtonSwitch;