import React from 'react';
import Detail from '../components/Detail';

const Wettbewerb = (props) => {
  return (
    <Detail 
      apiEndpoint="/collections/get/contests"
      back="/wettbewerbe"
      filter={props.match.params.slug}
    />
  );
}

export default Wettbewerb;