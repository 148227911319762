import React from 'react';
import css from './Toggle.module.css';

const Toggle = (props) => {
  let icon = null;

  if (props.icon) {
    icon = (
      <svg width="20px" height="20px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <polygon id="Shape" points="19.483871 0.0740740741 19.483871 3.55555556 0.064516129 3.55555556 0.064516129 0.0740740741"></polygon>
        <polygon id="Shape" points="19.483871 15.8518519 19.483871 19.3333333 0.064516129 19.3333333 0.064516129 15.8518519"></polygon>
        <polygon id="Shape" points="19.483871 8 19.483871 11.4814815 0.064516129 11.4814815 0.064516129 8"></polygon>
      </svg>
    );
  } else {
    icon = (
      <svg width="21px" height="21px" xmlns="http://www.w3.org/2000/svg">
        <g id="toggle-close" fill="#FFFFFF">
          <polygon id="Shape" points="20.2 2.40769231 17.9606178 0.1 10.23861 7.79230769 2.51660232 0.1 0.2 2.40769231 7.92200772 10.1 0.2 17.7923077 2.51660232 20.1 10.23861 12.4076923 17.9606178 20.1 20.2 17.7923077 12.4779923 10.1"></polygon>
        </g>
      </svg>
    );
  }

  return (
    <button onClick={props.clicked} className={css.Toggle} id="toggle">
      {icon}
    </button>
  );
}
  
export default Toggle;