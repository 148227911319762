import React from 'react';
import css from './Back.module.css';

class Back extends React.Component {
  
  

  componentDidMount() {
    let backSlot = document.getElementById('backSlot');
    let backComponent = document.getElementById('backComponent');

    backSlot.appendChild(backComponent);
  }

  render() {
    return (
      <a href={this.props.url} className={css.Back} id="backComponent">
        <svg width="21px" height="21px" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
          <g id="back" fill="#000000">
            <polygon id="Shape" points="20.2 2.40769231 17.9606178 0.1 10.23861 7.79230769 2.51660232 0.1 0.2 2.40769231 7.92200772 10.1 0.2 17.7923077 2.51660232 20.1 10.23861 12.4076923 17.9606178 20.1 20.2 17.7923077 12.4779923 10.1"></polygon>
          </g>
        </svg>
      </a>
    );
  }

}

/*const Back = (props) => (
  <a href={props.url} className={css.Back}>
    <svg width="21px" height="21px" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
      <g id="back" fill="#000000">
        <polygon id="Shape" points="20.2 2.40769231 17.9606178 0.1 10.23861 7.79230769 2.51660232 0.1 0.2 2.40769231 7.92200772 10.1 0.2 17.7923077 2.51660232 20.1 10.23861 12.4076923 17.9606178 20.1 20.2 17.7923077 12.4779923 10.1"></polygon>
      </g>
    </svg>
  </a>
);*/

export default Back;