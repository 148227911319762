import React from 'react';
import { Link } from 'react-router-dom';
import css from './Logo.module.css';

const Header = () => (
  
    <Link to="/" className={`${css.LogoText} logo-text`}>
      <svg width="290px" height="20px" xmlns="http://www.w3.org/2000/svg">
        <g id="Group">
          <path d="M0.666666667,16.6666667 L2.07407407,15.037037 C3.40740741,16.4444444 5.25925926,17.3333333 7.18518519,17.3333333 C9.62962963,17.3333333 11.037037,16.1481481 11.037037,14.2962963 C11.037037,12.4444444 9.7037037,11.8518519 8,11.037037 L5.33333333,9.85185185 C3.55555556,9.11111111 1.55555556,7.77777778 1.55555556,5.11111111 C1.55555556,2.2962963 4,0.222222222 7.33333333,0.222222222 C9.55555556,0.222222222 11.4814815,1.18518519 12.8148148,2.51851852 L11.5555556,4.07407407 C10.4444444,3.03703704 9.11111111,2.2962963 7.33333333,2.2962963 C5.25925926,2.2962963 3.92592593,3.33333333 3.92592593,5.03703704 C3.92592593,6.81481481 5.55555556,7.48148148 6.96296296,8.07407407 L9.55555556,9.18518519 C11.7037037,10.1481481 13.3333333,11.4074074 13.3333333,14.1481481 C13.3333333,17.1111111 10.962963,19.4074074 7.03703704,19.4074074 C4.51851852,19.4074074 2.2962963,18.3703704 0.666666667,16.6666667 Z" id="Shape"></path>
          <path d="M18.4444444,0.592592593 L23.7037037,0.592592593 C27.8518519,0.592592593 30.6666667,2 30.6666667,6 C30.6666667,9.85185185 27.9259259,11.7037037 23.8518519,11.7037037 L20.8148148,11.7037037 L20.8148148,19.037037 L18.4444444,19.037037 L18.4444444,0.592592593 Z M23.5555556,9.85185185 C26.7407407,9.85185185 28.2962963,8.66666667 28.2962963,6.07407407 C28.2962963,3.40740741 26.6666667,2.51851852 23.4074074,2.51851852 L20.7407407,2.51851852 L20.7407407,9.85185185 L23.5555556,9.85185185 Z" id="Shape"></path>
          <path d="M35.2592593,0.592592593 L41.037037,0.592592593 C44.8148148,0.592592593 47.4814815,1.92592593 47.4814815,5.77777778 C47.4814815,8.66666667 45.8518519,10.3703704 43.3333333,11.037037 L48.0740741,19.1111111 L45.4074074,19.1111111 L40.962963,11.3333333 L37.6296296,11.3333333 L37.6296296,19.1111111 L35.2592593,19.1111111 L35.2592593,0.592592593 Z M40.6666667,9.33333333 C43.5555556,9.33333333 45.1111111,8.14814815 45.1111111,5.77777778 C45.1111111,3.33333333 43.4814815,2.44444444 40.6666667,2.44444444 L37.5555556,2.44444444 L37.5555556,9.33333333 L40.6666667,9.33333333 Z" id="Shape"></path>
          <polygon id="Shape" points="52.7407407 0.592592593 63.4074074 0.592592593 63.4074074 2.59259259 55.1111111 2.59259259 55.1111111 8.37037037 62.1481481 8.37037037 62.1481481 10.3703704 55.1111111 10.3703704 55.1111111 17.1111111 63.7037037 17.1111111 63.7037037 19.1111111 52.7407407 19.1111111"></polygon>
          <polygon id="Shape" points="68.962963 0.592592593 79.6296296 0.592592593 79.6296296 2.59259259 71.3333333 2.59259259 71.3333333 8.37037037 78.3703704 8.37037037 78.3703704 10.3703704 71.3333333 10.3703704 71.3333333 17.1111111 79.9259259 17.1111111 79.9259259 19.1111111 68.962963 19.1111111"></polygon>
          <path d="M85.1851852,0.592592593 L87.6296296,0.592592593 L94.2962963,12.2222222 L96.2962963,16.0740741 L96.4444444,16.0740741 C96.2962963,14.2222222 96.1481481,12.1481481 96.1481481,10.1481481 L96.1481481,0.592592593 L98.3703704,0.592592593 L98.3703704,19.1111111 L95.9259259,19.1111111 L89.1851852,7.48148148 L87.1851852,3.7037037 L87.037037,3.7037037 C87.1851852,5.55555556 87.3333333,7.55555556 87.3333333,9.48148148 L87.3333333,19.1111111 L85.1111111,19.1111111 L85.1111111,0.592592593 L85.1851852,0.592592593 Z" id="Shape"></path>
          <path d="M117.407407,0.592592593 L120.074074,0.592592593 L126.296296,19.1111111 L123.777778,19.1111111 L122,13.4814815 L115.259259,13.4814815 L113.481481,19.1111111 L111.111111,19.1111111 L117.407407,0.592592593 Z M115.925926,11.5555556 L121.481481,11.5555556 L120.592593,8.74074074 C119.925926,6.66666667 119.333333,4.66666667 118.740741,2.51851852 L118.592593,2.51851852 C118,4.66666667 117.407407,6.66666667 116.740741,8.74074074 L115.925926,11.5555556 Z" id="Shape"></path>
          <path d="M130.296296,0.592592593 L136.074074,0.592592593 C139.851852,0.592592593 142.518519,1.92592593 142.518519,5.77777778 C142.518519,8.66666667 140.888889,10.3703704 138.37037,11.037037 L143.111111,19.1111111 L140.444444,19.1111111 L136,11.3333333 L132.666667,11.3333333 L132.666667,19.1111111 L130.296296,19.1111111 L130.296296,0.592592593 Z M135.777778,9.33333333 C138.666667,9.33333333 140.222222,8.14814815 140.222222,5.77777778 C140.222222,3.33333333 138.592593,2.44444444 135.777778,2.44444444 L132.666667,2.44444444 L132.666667,9.33333333 L135.777778,9.33333333 Z" id="Shape"></path>
          <path d="M146.666667,9.85185185 C146.666667,3.92592593 150.148148,0.296296296 154.888889,0.296296296 C157.111111,0.296296296 158.888889,1.33333333 159.925926,2.59259259 L158.666667,4.14814815 C157.703704,3.11111111 156.444444,2.37037037 154.888889,2.37037037 C151.407407,2.37037037 149.111111,5.25925926 149.111111,9.85185185 C149.111111,14.5185185 151.333333,17.4074074 154.814815,17.4074074 C156.592593,17.4074074 157.925926,16.5925926 159.111111,15.3333333 L160.444444,16.8148148 C158.962963,18.5185185 157.185185,19.4814815 154.814815,19.4814815 C150.148148,19.4074074 146.666667,15.8518519 146.666667,9.85185185 Z" id="Shape"></path>
          <polygon id="Shape" points="164.740741 0.592592593 167.111111 0.592592593 167.111111 8.37037037 175.703704 8.37037037 175.703704 0.592592593 178.074074 0.592592593 178.074074 19.1111111 175.703704 19.1111111 175.703704 10.3703704 167.111111 10.3703704 167.111111 19.1111111 164.740741 19.1111111"></polygon>
          <polygon id="Shape" points="184.518519 0.592592593 186.888889 0.592592593 186.888889 19.1111111 184.518519 19.1111111"></polygon>
          <polygon id="Shape" points="197.111111 2.59259259 191.555556 2.59259259 191.555556 0.592592593 205.111111 0.592592593 205.111111 2.59259259 199.555556 2.59259259 199.555556 19.1111111 197.185185 19.1111111 197.185185 2.59259259"></polygon>
          <polygon id="Shape" points="209.851852 0.592592593 220.518519 0.592592593 220.518519 2.59259259 212.222222 2.59259259 212.222222 8.37037037 219.259259 8.37037037 219.259259 10.3703704 212.222222 10.3703704 212.222222 17.1111111 220.740741 17.1111111 220.740741 19.1111111 209.777778 19.1111111 209.777778 0.592592593"></polygon>
          <polygon id="Shape" points="226.074074 0.592592593 228.444444 0.592592593 228.444444 9.85185185 228.518519 9.85185185 236.222222 0.592592593 238.888889 0.592592593 233.111111 7.62962963 239.777778 19.037037 237.185185 19.037037 231.62963 9.40740741 228.444444 13.1851852 228.444444 19.037037 226.074074 19.037037"></polygon>
          <polygon id="Shape" points="247.185185 2.59259259 241.62963 2.59259259 241.62963 0.592592593 255.185185 0.592592593 255.185185 2.59259259 249.62963 2.59259259 249.62963 19.1111111 247.259259 19.1111111 247.259259 2.59259259"></polygon>
          <polygon id="Shape" points="259.851852 0.592592593 270.518519 0.592592593 270.518519 2.59259259 262.222222 2.59259259 262.222222 8.37037037 269.259259 8.37037037 269.259259 10.3703704 262.222222 10.3703704 262.222222 17.1111111 270.814815 17.1111111 270.814815 19.1111111 259.851852 19.1111111"></polygon>
          <path d="M276.074074,0.592592593 L278.518519,0.592592593 L285.185185,12.2222222 L287.185185,16.0740741 L287.333333,16.0740741 C287.185185,14.2222222 287.037037,12.1481481 287.037037,10.1481481 L287.037037,0.592592593 L289.259259,0.592592593 L289.259259,19.1111111 L286.814815,19.1111111 L280.074074,7.48148148 L278.074074,3.7037037 L277.925926,3.7037037 C278.074074,5.55555556 278.222222,7.55555556 278.222222,9.48148148 L278.222222,19.1111111 L276,19.1111111 L276,0.592592593 L276.074074,0.592592593 Z" id="Shape"></path>
        </g>
      </svg>   
    </Link>
  
);

export default Header;