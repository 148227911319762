import React from 'react';
import { Link } from 'react-router-dom';
import css from './Logo.module.css';

const Logo = (props) => (
  <Link to="/" className={css.LogoSignet} onClick={props.clicked} id="logo">
    <svg width="26px" height="26px" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Group">
        <path d="M6.27058824,20.1117647 C8.56470588,20.1117647 9.86470588,19.1941176 9.86470588,17.7411765 C9.86470588,16.3647059 9.02352941,15.8294118 6.19411765,14.8352941 L4.12941176,14.1470588 C2.67647059,13.6117647 1.60588235,13 0.764705882,12.2352941 C0.764705882,12.5411765 0.688235294,12.8470588 0.688235294,13.2294118 C0.688235294,14.7588235 0.764705882,15.6 1.22352941,16.8235294 C2.06470588,18.8117647 4.20588235,20.1117647 6.27058824,20.1117647 Z" id="Shape"></path>
        <path d="M13.3823529,0.611764706 C10.4,0.611764706 7.72352941,1.60588235 5.58235294,3.28823529 C5.81176471,3.28823529 6.04117647,3.28823529 6.27058824,3.28823529 C9.55882353,3.28823529 12.0058824,4.66470588 13.5352941,7.18823529 L10.7823529,9.17647059 C9.48235294,7.03529412 7.87647059,6.27058824 5.96470588,6.27058824 C4.05294118,6.27058824 2.98235294,7.18823529 2.98235294,8.48823529 C2.98235294,9.71176471 3.67058824,10.3235294 6.11764706,11.1647059 L8.25882353,11.8529412 C11.7764706,13 13.3823529,14.5294118 13.3823529,17.4352941 C13.3823529,20.8764706 10.5529412,23.2470588 6.11764706,23.2470588 C5.88823529,23.2470588 5.65882353,23.2470588 5.42941176,23.1705882 C7.57058824,24.8529412 10.3235294,25.9235294 13.2294118,25.9235294 C20.1882353,25.9235294 25.8470588,20.2647059 25.8470588,13.3058824 C25.8470588,6.34705882 20.3411765,0.611764706 13.3823529,0.611764706 Z" id="Shape"></path>
      </g>
    </svg>
  </Link>
);

export default Logo;