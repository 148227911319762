import React from 'react';
import config from '../config';
import NeuesItem from '../components/NeuesItem';

class Neues extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      error: null,
      data: null
    };
  }

  componentDidMount() {
    fetch(`${config.apiBase}/collections/get/news`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        sort: {date:-1}
      })
    })
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            data: result.entries
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  }

  render() {
    const { error, isLoaded, data } = this.state;    
    let content = null;

    if (error) {
      content = <div>Error: {error.message}</div>
    } else if (!isLoaded) {
      content = <div className="loading-screen"><span>Loading...</span></div>;
    } else {
      console.log(data);
      content = data.map((item, index) => {
        let reverse = false;
        
        if (index % 2 === 0) {
          reverse = true;
        }

        const d = new Date(item.date);
        const day = d.getDate();
        const monthIndex = d.getMonth();
        const year = d.getFullYear();

        const formattedDate = `${day}. ${config.monthNames[monthIndex]} ${year}`;

        return (<NeuesItem key={index} reverse={reverse} date={formattedDate} headline={item.headline} text={item.text} image={item.image}/>);
      });
    }

    return (
      <section className="section">
        <div className="section-container">
          <h2 className="section-headline">Neues</h2>
          {content}
        </div>
      </section>
    );
  }
}

export default Neues;