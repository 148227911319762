import React from 'react';
import Detail from '../components/Detail';

const Projekt = (props) => {
  return (
    <Detail 
      apiEndpoint="/collections/get/projects"
      back="/projekte"
      filter={props.match.params.slug}
    />
  );
}

export default Projekt;