import React from 'react';
import css from './Table.module.css';

const Table = (props) => {
  let thead = null;
  let caption = null;

  if (props.thead) {
    const theadCell = props.thead.map((header, index) => {
      return (<th key={index} className={css.TableHeadCell}>{header}</th>);
    });

    thead = <thead className={css.TableHead}><tr className={css.TableHeadRow}>{theadCell}</tr></thead>;
  }
  
  if (props.caption) {
    caption = <caption className={css.TableCaption}>{props.caption}</caption>;
  }

  let trList = props.data.map((item, index) => {
    const td = Object.keys(item).map((key, colIndex) => {
      if (key !== 'link' && key !== 'linked') {
        if (key === 'project' && item['linked']) {
          return (<td key={`${index}_${colIndex}`} className={css.TableCell}><a href={`${props.linkbase}/${item['link']}`}>{item[key]}</a></td>);  
        }
        return (<td key={`${index}_${colIndex}`} className={css.TableCell} dangerouslySetInnerHTML={{ __html: item[key] }}></td>);
      }
    });

    return (
      <tr key={index} className={css.TableRow}>
        {td}
      </tr>
    );
  });

  return (
    <div className={css.TableContainer}>
      <table className={css.Table} id={props.cssID}>
        {caption}
        {thead}
        <tbody>
          {trList}
        </tbody>
      </table>
    </div>
  );
}

export default Table;