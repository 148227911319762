import React, { Fragment } from 'react';
import css from './SliderArrows.module.css';

const SliderArrows = (props) => {

  return (
    <Fragment>
      <button onClick={props.prev} className={[css.SliderArrow, css.SliderArrowPrev].join(' ')}>
        <svg width="14px" height="28px" viewBox="0 0 18 28" xmlns="http://www.w3.org/2000/svg">
          <g id="Pfeil_links" fill="#000000">
            <polygon id="Shape" points="14.6 27.9 17.6 24.8 6.9 14.1 17.6 3.4 14.6 0.3 0.7 14.1"></polygon>
          </g>
        </svg>
      </button>
      <button onClick={props.next} className={[css.SliderArrow, css.SliderArrowNext].join(' ')}>
        <svg width="14px" height="28px" viewBox="0 0 18 28" xmlns="http://www.w3.org/2000/svg">
          <g id="Pfeil_rechts" fill="#000000">
            <polygon id="Shape" points="3.8 0.3 0.7 3.4 11.5 14.1 0.7 24.8 3.8 27.9 17.6 14.1"></polygon>
          </g>
        </svg>
      </button>
    </Fragment>
  );
}

export default SliderArrows;