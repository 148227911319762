import React from 'react';
import { Row, Col, setConfiguration } from 'react-grid-system';
import css from './NeuesItem.module.css';
import Parser from 'html-react-parser';
import config from '../config';

setConfiguration({ gutterWidth: 100 });

const NeuesItem = (props) => {
  let col = null;
  let image = props.image.path ? <img src={`${config.imageBase}/${props.image.path}`} alt="" className="img-fluid"/> : '';

  if(props.reverse) {
    col = (
      <Row className="row mb-base">
        <Col className="col" xs={12} md={6} push={{ md: 6 }}>
          <div className="container-text">
            <p>{props.date}</p>
            <h3>{props.headline}</h3>
            <div className={css.NewsItemDescription}>{Parser(props.text)}</div>
          </div>
        </Col>
        <Col className="col" xs={12} md={6} pull={{ md: 6 }}>
          { image }
        </Col>
      </Row>
    );
  } else {
    col = (
      <Row className="row mb-base">
        <Col className="col" xs={12} md={6}>
          <div className="container-text">
            <p>{props.date}</p>
            <h3>{props.headline}</h3>
            <div className={css.NewsItemDescription}>{Parser(props.text)}</div>
          </div>
        </Col>
        <Col className="col" xs={12} md={6}>
          { image }
        </Col>
      </Row>
    );
  }

  return (
    <div className="container">
      {col}
    </div>
  );
};

export default NeuesItem;