import React from 'react';
import { Row, Col } from 'react-grid-system';
import config from '../config';

class Impressum extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      error: null,
      data: null
    };
  }

  componentDidMount() {
    fetch(`${config.apiBase}/singletons/get/imprint`)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            data: result
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  }

  render() {
    const { error, isLoaded, data } = this.state;    
    let content = null;

    if (error) {
      content = <div>Error: {error.message}</div>
    } else if (!isLoaded) {
      content = <div className="loading-screen"><span>Loading...</span></div>;
    } else {
      
      content = (
        <div className="container">
          <div className="container-text">
            <Row className="row mb-base">
              <Col className="col" xs={12}>
                <span dangerouslySetInnerHTML={{ __html: data.imprint }}></span>
              </Col>
            </Row>
            <Row className="row mb-base">
              <Col className="col" xs={12}>
                <span dangerouslySetInnerHTML={{ __html: data.privacy_policy }}></span> 
              </Col>
            </Row>
          </div>
        </div>
      );
    }

    return (
      <section className="section">
        <div className="section-container">
          <h2 className="section-headline">Impressum und Datenschutz</h2>
          {content}
        </div>
      </section>
    );
  }
};

export default Impressum;