import React from 'react';
//import Slider from 'react-animated-slider';
import config from '../config';
import css from './Home.module.css';
import 'react-animated-slider/build/horizontal.css';
import SliderArrows from '../components/SliderArrows';
import 'whatwg-fetch'; 
import Preload from 'react-preload';
import PreloaderIcon from 'react-preloader-icon';
import Oval from 'react-preloader-icon/loaders/Oval';
import Slider from "react-slick";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      error: null,
      data: null
    };

    this.handlePrev = this.handlePrev.bind(this);
    this.handleNext = this.handleNext.bind(this);
  }

  componentDidMount() {
    fetch(`${config.apiBase}/singletons/get/home`)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            data: result.gallery
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  }

  handlePrev() {
    //this.sliderRef.previous();
    this.slider.slickPrev();
  }
  
  handleNext() {
    //this.sliderRef.next();
    this.slider.slickNext();
  }

  render() {
    const { error, isLoaded, data } = this.state;    
    let content = null;

    if (error) {
      content = <div>Error: {error.message}</div>
    } else if (!isLoaded) {
      content = <div className="loading-screen"><span>Loading...</span></div>;
    } else {
      const items = data.map((item, index) => <div key={index} ><div style={{backgroundImage: `url(${config.imageBase}/${item.path})`}} className={css.HomeSlide}></div></div>);
      const settings = {
        dots: false,
        infinite: true,
        //speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 3500,
        lazyLoad: 'progressive',
        cssEase: "linear"
      };
      content = (
        /*<Slider 
          infinite={true} 
          duration={1000}
          autoplay={5000}
          ref={ref => (this.sliderRef = ref)}
          previousButton={null}
          nextButton={null}
          style={{ height: 'auto' }}>
          
          {items}
          
        </Slider>*/

        <Slider ref={c => (this.slider = c)} {...settings}>
          {items} 
        </Slider>
      );
    }

    return (
      <section className={css.Home} id="home">
        <SliderArrows prev={this.handlePrev} next={this.handleNext}/>
        {content}
      </section>
    );
  }
}

export default Home;