import React, { Fragment } from 'react';
import { Row, Col } from 'react-grid-system';
import config from '../config';
import Parser from 'html-react-parser';
import Table from '../components/Table';
import css from './Buero.module.css';
import ButtonSwitch from '../components/ButtonSwitch';

class Buero extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      error: null,
      data: null
    };
  }

  componentDidMount() {
    fetch(`${config.apiBase}/singletons/get/office`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ populate: 1 })
    })
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            data: result
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  }

  render() {
    const { error, isLoaded, data } = this.state;    
    let content = null;

    if (error) {
      content = <div>Error: {error.message}</div>
    } else if (!isLoaded) {
      content = <div className="loading-screen"><span>Loading...</span></div>;
    } else {
      const tableDataJan = data.profile_jan.map((item, index) => {
        return { definition: item.value.definition, text: item.value.text };
      });

      const tableDataAnne = data.profile_angela.map((item, index) => {
        return { definition: item.value.definition, text: item.value.text };
      });

      const tableDataJudge = data.judge_table
        .sort((a, b) => parseInt(b.value.definition) - parseInt(a.value.definition))
        .map((item, index) => {
          return { definition: item.value.definition, text: item.value.text, place: item.value.place };
        });

      const awardsTable = data.awards
        .sort((a, b) => parseInt(b.value.year) - parseInt(a.value.year))
        .map((award, index) => {
          return { year: award.value.year, award: award.value.price, project: award.value.project}
        });

      const publicationsTable = data.publications
        .sort((a, b) => parseInt(b.value.definition) - parseInt(a.value.definition))
        .map((publication) => {
          return { date: publication.value.definition, source: publication.value.source, project: publication.value.project }
        });

      content = (
        <Fragment>
          <div className="section-container">
            <div className="container">
              <Row className="row mb-base">
                <Col className="col" xs={12}>
                  <img src={`${config.imageBase}/${data.intro_image.path}`} alt="" className="img-fluid"/>
                </Col>
              </Row>
              <Row className="row mb-base">
                <Col className="col" xs={12}>
                  <div className="container-text">
                    <div className={css.BueroIntro}>{data.intro_text ? Parser(data.intro_text) : ''}</div>
                  </div>
                </Col>
              </Row>
              <Row className="row">
                <Col className="col" xs={12} md={6}>
                  <img src={`${config.imageBase}/${data.jan_image.path}`} alt="" className="img-fluid mb-small"/>
                  <div className="container-text table-vita">
                    <Table caption="Jan Spreen" data={tableDataJan}/>
                  </div>
                </Col>
                <Col className="col" xs={12} md={6}>
                  <img src={`${config.imageBase}/${data.angela_image.path}`} alt="" className="img-fluid mb-small"/>
                  <div className="container-text table-vita">
                    <Table caption="Angela Spreen" data={tableDataAnne}/>
                  </div>
                </Col>
              </Row>
            </div>
          </div>

          <div className="section-container">
            <div className="container">
              <Row className="row mb-base">
                <Col xs={12} md={6} className={`${css.BueroGalleryLeft} col`}>
                  <img src={`${config.imageBase}/${data.gallery[0].path}`} alt="" className="img-fluid mb-small"/>
                  <img src={`${config.imageBase}/${data.gallery[1].path}`} alt="" className="img-fluid mb-small"/>
                </Col>
                <Col xs={12} md={6} className={`${css.BueroGalleryRight} col`}>
                  <img src={`${config.imageBase}/${data.gallery[2].path}`} alt="" className="img-fluid mb-small"/>
                  <img src={`${config.imageBase}/${data.gallery[3].path}`} alt="" className="img-fluid mb-small"/>
                </Col>
              </Row>
            </div>
          </div>
          
          <div className="section-container">
            <div className="container">
              <Row className="row">
                <Col className="col" xs={12}>
                  <img src={`${config.imageBase}/${data.team_image.path}`} alt="" className="img-fluid mb-smaller"/>
                </Col>
              </Row>
            </div>
          </div>

          <div className="section-container">
            <div className="container">
              <div className="container-text">
                <h2 className="section-headline-sub">Team</h2>
                <div dangerouslySetInnerHTML={{ __html: data.team }} className="mb-smaller"></div>
              </div>
            </div>
          </div>

          <div className="section-container">
            <div className="container">
              <Row className="row">
                {/* <Col className="col" xs={12}>
                  <img src={`${config.imageBase}/${data.mood_image.path}`} alt="" className="img-fluid mb-smaller"/>
                </Col> */}
                {data.baukultur_gallery.map(image => (
                  <Col className="col mb-smaller" xs={12} md={6} lg={4}><img style={{width: '100%', marginBottom: ''}} src={`${config.imageBase}/${image.path}`} alt=""/></Col>
                ))}
              </Row>
            </div>
          </div>

          <div className="section-container">
            <div className="container">
              <div className="container-text">
                <ButtonSwitch label="Preisrichtertätigkeit" target="jurytable"/>
                <Table cssID="jurytable" data={tableDataJudge}/>
              </div>  
              <div className="container-text">
                <ButtonSwitch label="Preise / Auszeichnungen" target="awardstable"/>
                <Table cssID="awardstable" thead={['Jahr', 'Preis', 'Projekt']} data={awardsTable}/>
              </div>
              <div className="container-text">
                <ButtonSwitch label="Veröffentlichungen" target="publicationstable"/>
                <Table cssID="publicationstable" thead={['Datum', 'Quelle', 'Projekt']} data={publicationsTable}/>
              </div>  
            </div>
          </div>
        </Fragment>
      );
    }

    return (
      <section className="section">
        <h2 className="section-headline">Büro</h2>
        {content}
      </section>
    );
  }
}

export default Buero;