const config = {
  apiBase: 'https://admin.spreen-architekten.de/api',
  imageBase: 'https://admin.spreen-architekten.de',
  //apiBase: 'http://spreen-admin.zirkusdigitalo.de/api',
  //imageBase: 'http://spreen-admin.zirkusdigitalo.de',
  //apiBase: 'http://admin.spreen-architekten.localhost/api',
  //imageBase: 'http://admin.spreen-architekten.localhost',
  monthNames: [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember'
  ]
}

export default config;