import React from 'react';
import Masonry from '../components/Masonry';
import config from '../config';
import ButtonSwitch from '../components/ButtonSwitch';
import Table from '../components/Table';

class Wettbewerbe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      error: null,
      data: null
    };
  }

  componentDidMount() {
    fetch(`${config.apiBase}/collections/get/contests`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        fields: {poster: 1, title: 1, slug: 1, highlight: 1, show_in_grid: 1, year: 1, price: 1, method: 1}, 
        filter: {publish: true},
        sort: {_o: 1}
      })
    })
      .then(res => res.json())
      .then(
        (result) => {
          let gridEntries = result.entries.slice().filter(item => item.show_in_grid);
          let tableEntries = result.entries.map(item => {
            return {
              year: item.year,
              project: item.title,
              method: item.method,
              price: item.price,
              link: item.slug,
              linked: item.show_in_grid
            }
          }).sort((a, b) => b.year - a.year);

          this.setState({
            isLoaded: true,
            data: { 
              table: tableEntries,
              grid: gridEntries
            }
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  }

  render() {
    const { error, isLoaded, data } = this.state;    
    let content = null;
    let dataTable = [];

    if (error) {
      content = <div>Error: {error.message}</div>
    } else if (!isLoaded) {
      content = <div className="loading-screen"><span>Loading...</span></div>;
    } else {
      content = (
        <div className="container">
          <Masonry data={data.grid} destination="/wettbewerb"/>
        </div>
      );

      dataTable = data.table;
    }

    return (
      <section className="section">
        <div className="section-container">
          <h2 className="section-headline">Wettbewerbe</h2>
          {content}
        </div>

        <div className="section-container">
          <ButtonSwitch label="Alle Wettbewerbe" target="allconteststable"/>
          <div className="container">
            <div className="container-text">
              <Table linkbase="wettbewerb" cssID="allconteststable" thead={['Jahr', 'Projekt', 'Verfahren', 'Preis']} data={dataTable}/>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Wettbewerbe;