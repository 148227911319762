import React from 'react';
import config from '../config';
import SliderArrows from '../components/SliderArrows';
import Back from '../components/Back';
import { Row, Col, setConfiguration } from 'react-grid-system';
//import Slider from 'react-animated-slider';
import css from './Detail.module.css';
import Parser from 'html-react-parser';
import 'react-animated-slider/build/horizontal.css';
import AutoFitImage from 'react-image-autofit-frame';
import Preload from 'react-preload';
import PreloaderIcon from 'react-preloader-icon';
import Oval from 'react-preloader-icon/loaders/Oval';
import Slider from "react-slick";

setConfiguration({ gutterWidth: 100 });

class Detail extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      error: null,
      data: null,
      frameWidth: null,
      frameHeight: null
    }
    this.handleWindowResize = this.handleWindowResize.bind(this);
    this.handlePrev = this.handlePrev.bind(this);
    this.handleNext = this.handleNext.bind(this);
  }

  componentDidMount() {
    fetch(`${config.apiBase}${this.props.apiEndpoint}`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({filter: {slug: this.props.filter}})
    })
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            data: result.entries[0]
          });
          this.handleWindowResize();
          window.addEventListener('resize', this.handleWindowResize);
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  }

  handleWindowResize() {
    const frame = document.getElementById('frame');
    this.setState(
      {frameWidth: `${frame.offsetWidth}px`, frameHeight: `${frame.offsetWidth / 1.5}px`}
    );

    frame.style.height = `${frame.offsetWidth / 1.5}px`;
  }

  handlePrev() {
    //this.sliderRef.previous();
    this.slider.slickPrev();
  }
  
  handleNext() {
    //this.sliderRef.next();
    this.slider.slickNext();
  }

  render () {
    const { error, isLoaded, data } = this.state;

    if (error) {
      return <div>Error: {error.message}</div>
    } else if (!isLoaded) {
      return <div className="loading-screen"><span>Loading...</span></div>
    } else {
      //const items = data.gallery.map((item, index) => <div key={index} ><AutoFitImage frameWidth={this.state.frameWidth} frameHeight={this.state.frameHeight} imgSrc={`${config.imageBase}/${item.path}`} imgSize="contain"/></div>);
      const items = data.gallery.map((item, index) => {
        let img = new Image();
        img.src = `${config.imageBase}/${item.path}`;

        if (img.width > img.height) {
          //return (<div key={index} ><AutoFitImage frameWidth={this.state.frameWidth} frameHeight={this.state.frameHeight} imgSrc={`${config.imageBase}/${item.path}`} imgSize="cover"/></div>);  
          return (<div key={index} className={css.DetailFrameContainer}><AutoFitImage frameWidth={this.state.frameWidth} frameHeight={this.state.frameHeight} imgSrc={`${config.imageBase}/${item.path}`} imgSize="contain"/></div>);  
        } else {
          return (<div key={index} className={css.DetailFrameContainer}><AutoFitImage frameWidth={this.state.frameWidth} frameHeight={this.state.frameHeight} imgSrc={`${config.imageBase}/${item.path}`} imgSize="contain"/></div>);  
        }
        //console.log(img.width, img.height);
        //return (<div key={index} ><AutoFitImage frameWidth={this.state.frameWidth} frameHeight={this.state.frameHeight} imgSrc={`${config.imageBase}/${item.path}`} imgSize="contain"/></div>);
      });
      
      let dataClient = data.client_long ? <p className="mb-smaller"><small>Auftraggeber</small><br/><span dangerouslySetInnerHTML={{ __html: data.client_long }}></span></p> : '';
      let dataRealizationCompetition = data.realization_competition ? <p className="mb-smaller"><small>Realisierungswettbewerb</small><br/><span dangerouslySetInnerHTML={{ __html: data.realization_competition }}></span></p> : '';
      let dataNegotiatedProcedure = data.negotiated_procedure ? <p className="mb-smaller"><small>Verhandlungsverfahren</small><br/><span dangerouslySetInnerHTML={{ __html: data.negotiated_procedure }}></span></p> : '';
      let dataPlanningAndExecution = data.planning_and_execution ? <p className="mb-smaller"><small>Planung und Ausführung</small><br/><span dangerouslySetInnerHTML={{ __html: data.planning_and_execution }}></span></p> : '';
      let dataArchitecturalWork = data.architectural_work_long ? <p className="mb-smaller"><small>Architektenleistung</small><br/><span dangerouslySetInnerHTML={{ __html: data.architectural_work_long }}></span></p> : '';
      let dataBRI = data.bri ? <span><small>BRI:</small> {data.bri}</span> : '';
      let dataBGF = data.bgf ? <span><small>BGF:</small> {data.bgf}</span> : '';
      let dataNUF = data.nuf ? <span><small>NUF:</small> {data.nuf}</span> : '';
      let dataPhotos = data.photos ? <p className="mb-smaller"><small>Fotos</small><br/><span dangerouslySetInnerHTML={{ __html: data.photos }}></span></p> : '';
      let dataExtra = <p className="mb-smaller">{dataBRI} {dataBGF} {dataNUF}</p>;
      let dataAward = data.award ? <p className="mb-smaller"><small>Auszeichnung</small><br/><span dangerouslySetInnerHTML={{ __html: data.award }}></span></p> : '';
      let dataSubtitle = data.subtitle ? <p className={css.DetailSubtitle}>{ data.subtitle }</p> : '';
      //let dataYear = data.year ? <p className="mb-smaller"><small>Jahr</small><br/>{ data.year }</p> : '';
      //let dataMethod = data.method ? <p className="mb-smaller"><small>Verfahren</small><br/>{ data.method }</p> : '';

      let dataMethod = '';
      if (data.method) {
        dataMethod = <p className="mb-smaller"><small>Verfahren</small><br/>{ data.method }</p>;
      }
      if (data.method && data.method_extra) {
        dataMethod = <p className="mb-smaller"><small>Verfahren</small><br/>{ data.method }<br/>{ data.method_extra }</p>;
      }

      let dataMethodExtra = data.method_extra ? <p>{ data.method_extra }</p> : '';
      let dataPrice = data.price ? <p className="mb-smaller"><small>Preis</small><br/>{ data.price }</p> : '';

      let sliderArrows = data.gallery.length > 1 ? <SliderArrows prev={this.handlePrev} next={this.handleNext}/> : '';

      let titleClass = css.DetailTitleSpacing;

      if (dataSubtitle !== '') {
        titleClass = css.DetailTitle;
      }

      const preloadImages = data.gallery.map(item => `${config.imageBase}/${item.path}`);
      const preloadIndicator = (
        <div className="preloader-container" style={{ height: this.state.frameHeight, width: this.state.frameWidth }}>
          <PreloaderIcon
            loader={Oval}
            size={60}
            strokeWidth={8}
            strokeColor="#006064"
            duration={800}
          />
        </div>
      );

      const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      };

      return (

        <section className="section" id="project">
          <Back url={this.props.back}/>
          <div className="mb-small" style={{'position': 'relative'}}>
            { sliderArrows }
            <div className="section-container">
              <div className="container">
                <Row className="row">
                  <Col className="col" xs={12}>
                    <div className={css.DetailGallery} id="frame">
                      <Preload
                        loadingIndicator={preloadIndicator}
                        images={preloadImages}
                      >
                        <Slider ref={c => (this.slider = c)} {...settings}>
                          {items}
                        </Slider>
                      </Preload>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          <div>
            <div className="section-container">
              <div className="container">
                <Row className="row">
                  <Col className="col" xs={12} lg={5}>
                    <div className="container-text">
                      <h3 className={titleClass}>{data.title_long}</h3>
                      {dataSubtitle}

                      {dataClient}
                      {dataRealizationCompetition}
                      {dataNegotiatedProcedure}
                      {dataPlanningAndExecution}
                      {dataArchitecturalWork}
                      {dataExtra}
                      {dataPhotos}
                      {dataAward}
                      {dataMethod}
                      {dataPrice}
                    </div>
                  </Col>
                  <Col className="col" xs={12} lg={6}>
                    <div className="container-text">
                      {data.description ? Parser(data.description) : ''}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </section>

      );
    }
  }
}

export default Detail;